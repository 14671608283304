.LoginSocialButtons {
    clear: both;
}
.LoginSocialButtons iframe {
    margin: 0px auto !important;
    width: 240px !important;
}
.GoogleLoginBtn,
.FacebookLoginBtn,
.MobileLoginBtn {
    clear: both;
    text-align: center;
}
.GoogleLoginBtn .cont {
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: 0px auto;
    left: 0;
    right: 0px;
}
.FacebookLoginBtn button,
.GoogleLoginBtn button,
.MobileLoginBtn button {
    width: 220px;
    cursor: pointer;
    max-width: 100%;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    padding: 10px;
    border: 1px solid #dedede;
    background: transparent;
}
.FacebookLoginBtn img,
.GoogleLoginBtn img {
    max-width: 20px;
    margin-right: 10px;
}

.MobileLoginBtn i {
    margin-left: 5px;
    width: 20px;
    margin-right: 5px;
}
