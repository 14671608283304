.writeupBox {
    max-height: 150px;
    overflow: hidden;
}
.writeupBox:hover {
    max-height: 150px;
    overflow: auto;
}

.stdtestimg {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50% !important;
    margin-right: 15px !important;
    object-fit: contain;
    border: 1px solid #a662c9;
}
