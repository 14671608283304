.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

.react-calendar {
    width: 100%;
    background: transparent;
    border: none;
}

.react-calendar__navigation .react-calendar__navigation__label {
    font-size: 22px;
}

.react-calendar__navigation button {
    font-size: 28px;
    font-weight: 500;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}

.react-calendar__month-view__days__day--weekend {
    color: #000000;
}

.react-calendar__tile--now {
    background: #d3d3d3;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #d3d3d3;
}

.react-calendar__tile--active {
    background: #7f13ab;
    color: #ffffff;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #d3d3d3;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #7f13ab;
}

abbr[data-original-title],
abbr[title] {
    text-decoration: none;
    cursor: default;
}

.react-datepicker__portal {
    z-index: 999;
}
