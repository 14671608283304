.CPPrdPrice {
    float: right;
}
.clearBoth {
    clear: both;
}
.CPPrdPriceMobile {
    display: none;
}
@media (max-width: 767px) {
    .CPPrdPrice {
        display: none !important;
    }
    .CPPrdPriceMobile {
        display: block;
    }
}
