.pagetitle {
    color: #7f13ab;
}

.bulletpoint {
    background: #7f13ab;
    border-radius: 50%;
    padding: 4px;
    color: #fff;
    font-size: 10px;
}

.bookaclass_frm {
    background: #dee7ff;
    margin-bottom: 0px !important;
}

.clientsBox {
    display: flex;
    flex-wrap: wrap;
}

.clientsBox img {
    width: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #dddddd;
}
