.ryPopUpBack {
    position: fixed;
    top: 0;
    z-index: 9999988;
    background: #0000009e;
    left: 0;
    right: 0;
    bottom: 0;
}
.ryPopUp {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999999;
    background: #fff;
    left: 0;
    right: 0;
    padding: 20px;
    margin: auto;
    max-width: max-content;
    max-height: 100%;
    height: fit-content;
    overflow: auto;
}
.ryPopUpAct {
    background: #333;
    color: #f5f5f5;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    float: right;
    margin-bottom: 30px;
}
.ryuploadAct:hover {
    background: #333;
    color: #fff;
}
.ryuploadAct {
    border: 1px solid #f5f5f5;
    display: block;
    padding: 10px;
    background: #7f7f7f;
    color: #f5f5f5;
    text-align: center;
}
.crearboth {
    clear: both;
}
.file_inp_cont {
    border: 1px dashed #4f6a97;
    padding: 30px;
    margin: 30px auto;
}

@media (max-width: 1023px) {
    .file_inp_cont {
        border: 0px dashed #000;
        padding: 10px;
        margin: 10px auto;
    }
}

.file_inp_cont .ryfile {
    width: 100%;
    border: 1px solid #f5f5f5;
    padding: 10px;
    margin-bottom: 20px;
}
