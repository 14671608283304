/* for the Course HTML Description DESKTOP */
.CourseDescription {
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
    padding: 30px 30px;
    border-radius: 10px;
    text-align: left;
}
.CourseDescription ul {
    list-style-type: disc;
    padding-left: 10px;
}
.CourseDescription h4,
.CourseDescription h4 span {
    color: #7a3694 !important;
    letter-spacing: 1px;
    font-size: 26px !important;
    font-family: 'Lato', sans-serif !important;
}
.CourseDescription h4 {
    margin-bottom: 15px;
    line-height: 32px !important;
}

.CourseDescription p,
.CourseDescription ul li,
.CourseDescription p span,
.CourseDescription ul li span,
.CourseDescription p a,
.CourseDescription ul li a {
    color: #0f0f0f !important;
    font-size: 15px !important;
    text-align: left !important;
    line-height: 24px;
}

.CourseDescription p {
    margin-bottom: 10px !important;
    letter-spacing: 0.5px;
    font-family: 'Lato', sans-serif !important;
}

.CourseDescription p span {
    letter-spacing: 0.5px;
    font-family: 'Lato', sans-serif !important;
}
.CourseDescription ul li {
    letter-spacing: 0.5px;
    font-family: 'Lato', sans-serif !important;
}

.CourseDescription ul li span {
    letter-spacing: 0.5px;
    font-family: 'Lato', sans-serif !important;
}
/* for the Course HTML Description DESKTOP */

/* for the Course HTML Description CourseDescriptionMobile */

.CourseDescriptionMobile1 {
    padding: 0px;
}
.CourseDescriptionMobile ul {
    list-style-type: disc;
    padding-left: 20px;
}
.CourseDescriptionMobile h4,
.CourseDescriptionMobile h4 span {
    color: #7a3694 !important;
    letter-spacing: 1px;
    font-size: 26px !important;
    font-family: 'Lato', sans-serif !important;
}
.CourseDescriptionMobile h4 {
    margin-bottom: 15px;
    line-height: 32px !important;
}

.CourseDescriptionMobile p,
.CourseDescriptionMobile ul li,
.CourseDescriptionMobile p span,
.CourseDescriptionMobile ul li span,
.CourseDescriptionMobile p a,
.CourseDescriptionMobile ul li a {
    color: #0f0f0f !important;
    font-size: 15px !important;
    text-align: left !important;
    line-height: 24px;
}

.CourseDescriptionMobile p {
    margin-bottom: 10px !important;
    letter-spacing: 0.5px;
    font-family: 'Lato', sans-serif !important;
}

.CourseDescriptionMobile p span {
    letter-spacing: 0.5px;
    font-family: 'Lato', sans-serif !important;
}
.CourseDescriptionMobile ul li {
    letter-spacing: 0.5px;
    font-family: 'Lato', sans-serif !important;
}

.CourseDescriptionMobile ul li span {
    letter-spacing: 0.5px;
    font-family: 'Lato', sans-serif !important;
}
/* for the Course HTML Description CourseDescriptionMobile */
.previewVideo {
    /* float: right; */
    cursor: pointer;
    color: #7f13ab;
    padding: 2px 5px;
    border-radius: 50px;
    cursor: pointer;
    text-decoration: underline;
}
.instructorBox .instructorBoxImg {
    height: 130px !important;
    object-fit: cover !important;
    width: 130px !important;
    max-width: 100% !important;
    border-radius: 50% !important;
    border: 1px solid #f5f5f5;
}
@media only screen and (min-width: 1023px) {
    .buyBoxCourse {
        margin-top: -350px;
        z-index: 10;
    }
}
/* 
@media only screen and (max-width: 999px) {
   .buyBoxCourse {
      display: none;
   }
} */
.PreviewCourseBanner {
    color: #f5f5f5;
    text-align: center;
    position: absolute;
    padding-top: 18%;
    z-index: 10;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #00000078;
}
.buyBoxCourse .imgBanner {
    max-width: 100%;
    max-height: 170px;
    object-fit: cover;
    width: 100%;
}
.HideTempFreeSample {
    display: none;
}
.ShowTimeFreeSample {
    /* padding-top: 15px; */
    padding-top: auto;
}
.RowsFreeSample {
    cursor: pointer;
    /* padding: 10px 0px; */
}
/* for the preview video start here */
.PreviewVideoPopUp {
    z-index: 99999997;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
    overflow-y: scroll;
    max-height: 100%;
    max-width: 100%;
}
.PreviewVideoContent {
    z-index: 99999999;
    position: relative;
    max-width: 40rem;
    min-width: 300px;
    margin: 5% auto;
    background: #2d2f31;
    padding: 15px;
}
.PreviewVideoPopUpBack {
    background: #000000bd;
    z-index: 99999998;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
    height: 100%;
    width: 100%;
}
.PreviewCourseBanner {
    cursor: pointer;
}
/* for the preview video End here */
.CoursePPDuration {
    float: right;
    padding-right: 20px;
}
.clearboth,
.clrb {
    clear: both;
}
.CoursePPAllDuration {
    font-size: 15px;
}
@media only screen and (max-width: 1023px) {
    .CoursePPAllDuration {
        font-size: 10px;
    }
}
.fs10 {
    font-size: 10px;
}
.text-purple-time {
    font-weight: 600;
    color: #7a3694;
    letter-spacing: 1px;
}
.courseH1Tag {
    font-size: 1.8rem !important;
    line-height: 2.5rem !important;
}
.overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Half the screen height for mobile */
    background-color: rgba(0, 0, 0, 0.75); /* Black color with 75% opacity */
    display: flex;
    align-items: center; /* Align popup to bottom */
    justify-content: center;
    overflow: hidden;
    animation: slideUp 0.3s ease-in-out; /* Slide animation */
    z-index: 999999;
}

.popupContainer {
    position: fixed;
    bottom: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content; /* Half the screen height for mobile */
    border-radius: 10px;
}
.popup {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: min-content;
    background-color: #fff;
    padding: 20px;
    min-height: 50%; /* Half the screen height for larger screens */
    border-radius: 10px;
}
.validityOption {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
}
.selectedCourseValidity {
    background-color: #fcf4ff;
    border: 2px solid #7f13ab !important;
}
.selectedCheckbox {
    width: 1rem;
    height: 1rem;
    background-color: #7f13ab;
    border-radius: 100%;
}
.selectedPeriodRadio {
    background-color: #7f13ab; /* Change background color when checked */
}
.periodRadio {
    display: flex;
    margin-right: 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid #7f13ab;
    color: #7f13ab;
    width: 1rem;
    height: 1rem;
}

/* Animation keyframes */
@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@media (min-width: 768px) {
    .overlay {
        align-items: flex-end; /* Align popup to bottom */
    }
    /* Adjust height for larger screens */
    .popupContainer {
        top: 50%;
        min-height: 50%; /* Half the screen height for larger screens */
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .popup {
        min-width: 30rem;
    }
}
@media (max-width: 768px) {
    .periodRadio {
        display: none;
    }
    .overlay {
        align-items: flex-end; /* Align popup to bottom */
    }
    /* Adjust height for larger screens */
    .popupContainer {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .popup {
        bottom: 0;
        width: 100%;
        z-index: 999;
    }
}
