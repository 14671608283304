.blog_cati_cont {
    border: 1px solid #f5f5f5;
    border-radius: 50px;
    padding: 20px 10px;
    display: flex;
    text-align: left;
    margin-bottom: 20px;
    padding-left: 30px;
    color: #000;
    font-weight: 600;
    align-items: center;
}
.blog_cati_cont img {
    width: 56px;
    margin-right: 20px;
    object-fit: contain;
    height: 50px;
}
.tableRY td {
    border: 1px solid #f5f5f5;
}
