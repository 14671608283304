.QBCSVUpload {
    border: 2px solid #dedede;
}
.QBManualUpload {
    border: 2px solid #dedede;
}
.ListQuestionContainerAdmin {
    padding: 10px;
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
}
.uploadCSVQB {
    padding: 40px 30px;
    border: 1px solid #f5f5f5;
}
