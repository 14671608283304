.AB_course_description {
    display: -webkit-box !important;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    min-height: 10rem;
}

.AB_course_description:hover {
    /* display: block !important; */
    overflow: auto;
}

.Advisory_Doard_list .slick-next {
    top: 45% !important;
    background: #7a3694;
    height: 40px !important;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    border: 0px solid #fff;
    right: -20px !important;
    width: 30px !important;
}
.Advisory_Doard_list .slick-next:before {
    color: #fff !important;
}

.Advisory_Doard_list .slick-prev {
    top: 45% !important;
    background: #7a3694;
    height: 40px !important;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    border: 0px solid #fff;
    left: -20px !important;
    width: 30px !important;
}
.Advisory_Doard_list .slick-prev:before {
    color: #fff !important;
}
.appie-features-content .contentBox {
    padding: 10px 20px !important;
    background: #fff;
}
.advisoryBoardBox .imgBox img {
    width: 100%;
    /* height: 250px; */
    object-fit: contain;
}
.mentor_div {
    border: 1px solid #f5f5f5;
}
